import React, { useRef }from 'react';
import styled from 'styled-components';
import { MdClose } from 'react-icons/md';

const Background = styled.div`
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    position: fixed;
    top: 0;
    z-index: 999;
`;

const ModalWrapper = styled.div`
  width: 800px;
  height: 90vh;
  box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
  background: #fff;
  color: #000;
  position: relative;
  z-index: 10;
  border-radius: 10px;
  margin: auto;
  padding: 55px 40px;
  transform: translateY(5%);
  overflow: auto;
  @media (max-width: 768px) {
        width: 700px;
    }
   @media (max-width: 425px) {
        width: 90%;
    }
`;

const CloseModalButton = styled(MdClose)`
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
  width: 32px;
  height: 32px;
  padding: 0;
  z-index: 10;
`;



export const Modal = ({showModal, setShowModal, children}) => {
    const modalRef = useRef();

    const closeModal = e => {
        if (modalRef.current === e.target) {
            setShowModal(false);
        }
        document.body.style.overflow = "scroll";
    }
    return (
        <>
            {showModal ? (
                <Background ref={modalRef} onClick={closeModal}>
                    <ModalWrapper>
                        <CloseModalButton aria-label='Close modal' onClick={()=> setShowModal(false)}/>
                            <div>{children}</div>
                    </ModalWrapper>
                </Background>
            ) : null }
        </>
    )
}