import React, {useState} from "react";
import { graphql, Link } from "gatsby";
import PortableText from "@sanity/block-content-to-react";
import Layout from "../components/layout";
import Img from 'gatsby-image';
import { getFluidGatsbyImage } from 'gatsby-source-sanity';
import SEO from "../components/seo";
import Hero from "../components/hero";
import { useTranslation } from "react-i18next";
import { node } from "prop-types"
import styled from 'styled-components';
import { Modal } from "../components/modal";
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import urlFor from "../helper/urlFor";
import mBg from '../assets/images/donate-bg.png';
import pBg from '../assets/images/donate-bg.png';

const Donation = styled.div`
  background-color: #7dcbef;
  padding: 100px 150px;
  text-align: center;
  background-image: url(${mBg});
  background-repeat: no-repeat;
  background-size: 300px;
  background-position: center;
  a {
    text-decoration: none;
  }
  @media (max-width: 425px) {
    padding: 100px 30px;
  }
    h2 {
      font-size: 2.5rem;
      color: #22577A;
    }
    p {
      font-size: 1.2rem;
    }

    .button {
      margin: 15px;
    }
`;
const BankDetails = styled.div`
  font-size: 1.2rem;
  h2 {
    color: #FB8524;
    font-size: 3rem;
  }
  p {
    padding-bottom: 1rem;
    @media (max-width: 425px) {
      :last-child {
        word-break: break-all;
      }
    }
    strong {
      color: #219EBC;
      text-decoration: underline;
    }
  }
  .gatsby-image-wrapper {
    width: 50%;
    @media (max-width: 425px) {
      width: 100%;
    }
  }
`;

const Partner = styled.div`
  padding: 100px 150px;
  text-align: center;
  background-image: url(${pBg});
  background-repeat: no-repeat;
  background-size: 300px;
  background-position: center;
  @media (max-width: 425px) {
    padding: 100px 30px 30px; 
  }

  a {
    color: #fb8524;
    font-weight: 600;
  }
  ul {
    list-style: none;
    margin-bottom: 1rem;
  }
  h2 {
    font-size: 2.5rem;
    color: #22577A;
  }
  p {
    font-size: 1.2rem;
    padding-bottom: 1rem;
    width: 90%;
    margin: auto;
    @media (max-width: 425px) {
      width: 100%;
      &:nth-child(7) {
        word-break: break-all;
      }
    }
  }
`;

const PartnerWrapper = styled.div`
  text-align: center;
  padding: 50px 100px 100px 80px;
  @media (max-width: 425px) {
    padding: 50px 30px 80px;
  }
  h2, h3 {
    font-size: 2.5rem;
    padding-bottom: 2rem;
    color: #22577A;
  }
  h3 {
    font-size: 1.8rem;
    padding-bottom: 1.5rem;
  }
`;

const PartnersStyles = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  align-content: space-between;
  gap: 20px;
  padding-bottom: 3rem;
`;

const PartnerStyle = styled.div`
  flex: 1 1 30%;
  .gatsby-image-wrapper {
    width: 70%;
    margin: auto;
  }
`;

const sanityConfig = {projectId: '7nqypbju', dataset: 'production'}

const serializer = {
  types: {
    image: (props) => (
      <Img fluid={getFluidGatsbyImage(props.node.asset._ref, {maxWidth: 500}, sanityConfig)} />
    ),
  },
}

const InvolvedPage = ({data}) => {
  const { t, i18n } = useTranslation("involved");
  const [showModal, setShowModal] = useState(false);
  const [button, setButton] = useState([])
  const openModal = (e) => {
    document.body.style.overflow = "hidden";
    setButton(e.target.innerText);
    setShowModal(prev => !prev);
  }

  const modalData = () => (
    button === "DONATE CASH" ? 
    (
      <BankDetails>
        <PortableText blocks={sectionText[5].body} serializers={serializer}/>
      </BankDetails>
    ) : 
    (
      <div>
        <h3>{sectionText[4].title}</h3>
        <PortableText 
          blocks={sectionText[4].body}
        />
      </div>
    )
  )
  
  
 const sectionText = data.pages._rawContent.filter(content => content._type === "sectionText");
 const hero = data.pages._rawContent.filter(content => content._type === "hero");
 const enText = sectionText.filter(text => text.language === "en");
 const phText = sectionText.filter(text => text.language === "ph");
 const heroBg = urlFor(hero[0].bgImage.asset);
 console.log(sectionText[5])
  return (
    <Layout>
      <SEO title="Get Involved" />
        <Hero title={i18n.language === "en" ? hero[0].heading.en : hero[0].heading.ph} bg={heroBg} />
        <Donation>
            <h2>{i18n.language === 'en' ? enText[0].title : phText[0].title}</h2>
            { i18n.language === 'en' ? 
              <PortableText 
                blocks={enText[0].body}
              /> :
              <PortableText 
                blocks={phText[0].body}
              />
            }
            <div>
              <AnchorLink to={i18n.language === "en" ? "/en/get-involved#partner" : "/ph/get-involved#partner"} className="button">Donate in Kind</AnchorLink>
              <div className="button" onClick={openModal}>Donate Cash</div>
            </div>
        </Donation>
        <Partner id="partner">
            <h2>{i18n.language === 'en' ? enText[1].title : phText[1].title}</h2>
            { i18n.language === 'en' ? 
              <PortableText 
                blocks={enText[1].body}
              /> :
              <PortableText 
                blocks={phText[1].body}
              />
            }
        </Partner>
        <PartnerWrapper>
          <h2>Our Partners</h2>
          <PartnersStyles>
          {data.partners.nodes.map(partner => (
            <PartnerStyle key={partner.id}>
              <Img fluid={partner.image.asset.fluid} alt={partner.name} />
            </PartnerStyle>
          ))}
          </PartnersStyles>
          <div>
            <h3>Individual Donors</h3>
            <p>To view our list of Individual Donors:</p>
              <button className="button" onClick={openModal}>Click Here</button>
          </div>
        </PartnerWrapper>
        <Modal showModal={showModal} setShowModal={setShowModal}>
            {
              showModal ?
              modalData()
              : null
            }
             
        </Modal>
    </Layout>
  )
}

export const query = graphql`
 query {
  pages: sanityPages(name: {eq: "Get Involved"}) {
    _rawContent
    content {
      ... on SanityHero {
        _key
        _type
        bgImage {
          _key
          _type
        }
        label
      }
      ... on SanitySectionText {
        _key
        _type
        language
        title
      }
      ... on SanityTwoHeadings {
        _key
        _type
        label
      }
    }
  }
  partners: allSanityPartners(sort: {fields: _createdAt}) {
    nodes {
      id
      name
      image {
        asset {
          fluid (maxWidth: 100){
						...GatsbySanityImageFluid
          }
          id
        }
      }
    }
  }
}
`

export default InvolvedPage
