import React from 'react';
import styled from 'styled-components';

const HeroStyle = styled.div`
    text-align: center;
    background: url(${props => props.bg}) no-repeat center;
    background-size: cover;
    font-size: 3rem;
    color: #ffce7e;
    height: 700px;
    @media (max-width: 768px) {
        font-size: 2rem;
        height: 600px;
    }
    div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 100px;
      height: 700px;
      background-color: rgba(47, 47, 47, 0.41);
      @media (max-width: 768px) {
        height: 600px;
        padding: 50px;
      }
    }
`;

export default function Hero({title, bg}) {
    return (
        <HeroStyle bg={bg}>
            <div>
                <h2>{title}</h2>
            </div>
        </HeroStyle>
    )
}